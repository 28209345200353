.studentContainer {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-image: repeating-linear-gradient(45deg, #f8fafa, #f0f0f0 100px);
}

.studentHeader {
  width: 100%;
  height: 80px;
  background-color: #f5f5f5;
  display: flex;
  border-bottom: 1px solid whitesmoke;
}
.studentHeaderOne {
  width: 50%;
  height: 100%;
}
.studentHeaderTwo {
  width: 50%;
  height: 100%;
  //   background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.studentHeaderNav {
  padding: 0px;
  margin: 0px;
}
.studentHeaderNav li {
  display: inline-block;
  margin-left: 20px;
  padding: 10px;
  list-style: none;
  //   background-color: cadetblue;
}
.searchContainer {
  width: 100%;
  height: 500px;
  // background-color: red;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchContainerBox {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #fbfbfb;
  padding-bottom: 20px;
}
.searchContainerOne {
  width: 100%;
  height: 30%;
  //   background-color: firebrick;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.searchContainerTwo {
  width: 100%;
  height: 30%;
  //   background-color: rgb(34, 178, 120);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.searchContainerThree {
  width: 100%;
  height: 30%;
  //   background-color: rgb(178, 156, 34);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.searchContainerFour {
  width: 100%;
  height: 10%;
  //   background-color: rgb(178, 156, 34);
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchContainerFour button {
  border: none;
}
.queryContainer {
  width: 100%;
  min-height: 130px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
