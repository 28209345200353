@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockContainerComputerMain {
  width: 100%;
  min-height: 520px;
  // background-color: red;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .blockContainerComputerMain {
    display: none;
  }
}
