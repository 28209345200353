.navbarContainer {
  width: 100%;
  height: 70px;
  //   background-color: red;
  // background-color: #e4f3ff;
  // color: #151b20;

  // background-color: #0a2125;
  // color: white;

  background-color: #0a292d;
  color: #ececec;
  display: none;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 15px 10px -15px #111;
}
.navbarLogo {
  width: 40%;
  height: 100%;
  // background-color: orange;
  display: flex;
  align-items: center;
}
.navbarLogoOne {
  width: 14%;
  height: 100%;
  //   background-color: khaki;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.navbarLogoTwo {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.navbarLogoTwoTitle {
  font-size: 24px;
}
.logo {
  width: 50px;
  height: 50px;
}
.logoTitle {
  font-size: 20px;
  margin-left: 10px;
}

.navbarTitle {
  width: 60%;
  height: 100%;
  // background-color: #052722;
  //   background-color: #0a292d;
  //   color: rgb(192, 192, 192);
  //   background-color: orange;
  color: #b7a7a7;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navList {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  //   background-color: green;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navListItem {
  list-style-type: none;
  margin: 10px;
  padding: 10px;
  //   background-color: red;
  display: inline-block;
  cursor: pointer;
  // background-color: #0a3d44;
}
.itemHover:hover {
  background-color: #1a3c41;
  color: #ffffff;
  transition: 0.3s;
}
.itemHover:hover a {
  color: #ffffff;
  transition: 0.3s;
}

.navListItemA {
  color: #b7a7a7;
  text-decoration: none;
}

.navListItemA:hover {
  color: #ffffff;
}
.darkLightMode {
  width: 35px;
  height: 32px;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

.navbarTitleHeader {
  width: 100%;
  height: 70%;
  // background-color: green;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-family: "Poppins" sans-serif;
}

.navSelectLanguage {
  text-align: center;
  width: 80px;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  /* background-color: rgb(5, 5, 5); */
  background-color: rgba(190, 177, 177, 0.226);
  color: white;
}
.iconHamburger {
  display: none;
}
#navbarResponsive {
  display: none;
}

.navbarItems {
  list-style-type: none;
}
.navbarItemsList {
  //   background-color: red;
  margin-top: 10px;
  margin-right: 5px;
}
.navbarItemsList a {
  margin-left: 5px;
  transition: 0.3s;
  text-decoration: none;
  color: #959595;
}
.navbarItemsList:hover a {
  padding-left: 10px;
  transition: 0.3s;
  text-decoration: underline;
  color: white;
}

@media (max-width: 1300px) {
  .navbarLogoTwoTitle {
    font-size: 22px;
  }
}

@media (max-width: 1200px) {
  .navbarContainer {
    display: flex;
  }
  .navbarLogoTwoTitle {
    font-size: 20px;
  }
}

@media (max-width: 1188px) {
  .navbarLogo {
    width: 82%;
  }
  .navbarTitle {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    // background-color: red;
  }
  .navbarLogoTwo {
    justify-content: center;
  }
  .navbarLogoTwoTitle {
    font-size: 15px;
  }
  .navList {
    display: none !important;
  }
  .iconHamburger {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    color: white;
  }
  #navbarResponsive {
    display: flex;
  }
}
