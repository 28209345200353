@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockContainerThree {
  width: 100%;
  min-height: 500px;
  // background-color: red;
  display: flex;
}
.blockContainerThreeOne {
  width: 35%;
  height: 100%;
  // background-color: darkblue;
  padding-left: 20px;
  padding-right: 20px;
}
.blockContainerThreeOneTextContainer {
  width: 100%;
  height: 80px;
  // background-color: orange;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.blockContainerThreeOneItems {
  list-style-type: none;
}
.blockContainerThreeOneItems li {
  // background-color: red;
  margin-top: 10px;
  margin-right: 5px;
}
.blockContainerThreeOneItems li a {
  margin-left: 5px;
  transition: 0.3s;
  text-decoration: none;
  color: #5f5858;
}
.blockContainerThreeOneItems li:hover a {
  padding-left: 10px;
  transition: 0.3s;
  text-decoration: underline;
  color: #0b292d;
  // color: white;
}
.blockContainerThreeTwo {
  width: 65%;
  height: 100%;
  // background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

//

@media (max-width: 1024px) {
  .blockContainerThree {
    // height: 1050px;
    flex-direction: column;
  }

  .blockContainerThreeOne {
    width: 100%;
    height: 60%;
  }
  .blockContainerThreeTwo {
    width: 100%;
    height: 40%;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10px;
  }
}
