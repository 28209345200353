@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.tContainer {
  width: 318px;
  height: 400px;
  //   background-color: aqua;
  border-radius: 10px;

  background-color: aliceblue;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 2px #e3e3e3;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-position-y: 275px;
}
.theader {
  width: 100%;
  height: 8%;
  //   background-color: red;
}
.theaderInside {
  max-width: 80%;
  height: 100%;
  //   background-color: khaki;
  border-bottom-right-radius: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  background-color: #ddf0fd;
  font-family: system-ui, sans-serif;
}
.tImage {
  position: relative;
  width: 100%;
  height: 49%;
  //   background-color: teal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tImageHeader {
  width: 190px;
  height: 190px;
  //   background-color: orange;
  border-radius: 100%;
  box-shadow: 0px 0px 4px 1px #dedede;
}
.tImageHeaderIcon {
  position: absolute;
  width: 50px;
  height: 50px;
  //   background-color: white;
  bottom: 0;
  right: 70px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ghostwhite;
  box-shadow: 0px 0px 6px 0px #b9b9b9;
}
.tImageInside {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.tTitle {
  width: 100%;
  height: 14%;
  // background-color: rgb(19, 0, 128);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  // padding-top: 10px;
}
.tTitleOther {
  width: 100%;
  height: 12%;
  // background-color: rgba(53, 23, 224, 0.887);
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.tExtra {
  width: 100%;
  height: 17%;
  //   background-color: rgba(93, 224, 23, 0.887);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ddf0ff;
  border-top: 1px solid #f1f1f1;
}
