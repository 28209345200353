@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.homeContainer {
  background-color: white;
}

.firstGrid {
  width: 100%;
  height: 400px;
  // background-color: aqua;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  column-gap: 100px;
  row-gap: 50px;
}

.secondGrid {
  width: 100%;
  height: 800px;
  background-color: blue;
  display: flex;
}

.newNursingConatiner {
  width: 100%;
  height: auto;
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
  // justify-content: center;
  // align-items: center;
}

@media (max-width:1024px) {
  .newNursingConatiner {
    grid-template-columns: auto;
  }
}

.newNursingBlockOne {
  padding: 20px;
}

.newNursingBlockTwo {
  padding: 20px;
}