@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockImagesOneContainer {
  width: 100%;
  min-height: 500px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blockImagesOneContainerOne {
  width: 65%;
  height: 100%;
  // background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.blockImagesOneContainerTwo {
  width: 35%;
  height: 100%;
  // background-color: darkblue;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  padding-top: 10px;
}
//

@media (max-width: 1024px) {
  .blockImagesOneContainer {
    flex-direction: column;
  }

  .blockImagesOneContainerOne {
    width: 100%;
    height: 50%;
  }
  .blockImagesOneContainerTwo {
    width: 100%;
    height: 50%;
  }
}
