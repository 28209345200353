@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockForLinkTwoContainer {
  width: 100%;
  min-height: 400px;
  // background-color: red;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.blockForLinkTwoListContainer {
  width: 300px;
  height: 350px;
  // background-color: darkcyan;
  box-shadow: 0px 0px 6px 0px #d7d7d7;
}
.blockForLinkTwoListContainerTitle {
  width: 100%;
  height: 10%;
  background-color: #f2f1f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.blockForLinkTwoListContainerList {
  width: 100%;
  height: 90%;
  // background-color: #22b284;
  padding-top: 20px;
  background-size: contain;
  background-position-x: 70px;
  background-position-y: 60px;
}

.blockForLinkTwoListContainerListItems {
  list-style-type: none;
}
.blockForLinkTwoListContainerListItems li {
  //   background-color: red;
  margin-top: 10px;
  margin-right: 5px;
}
.blockForLinkTwoListContainerListItems li a {
  margin-left: 5px;
  transition: 0.3s;
  text-decoration: none;
  color: #757575;
}
.blockForLinkTwoListContainerListItems li:hover a {
  padding-left: 10px;
  transition: 0.3s;
  text-decoration: underline;
  // color: #0b292d;
  color: rgb(0, 0, 0);
}

@media (max-width: 730px) {
  .blockForLinkTwoContainer {
    grid-template-columns: auto;
    row-gap: 50px;
  }
}
