@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockContainerComputer {
  width: 100%;
  min-height: 400px;
  // background-color: red;
  margin-top: 10px;
}
.blockContainerComputerOne {
  width: 100%;
  height: 60px;
  // background-color: darkcyan;
  font-size: 35px;
}
.blockContainerComputerSecond {
  width: 100%;
  height: 85%;
  // background-color: darkgray;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  row-gap: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.blockContainerComputerSecondImage {
  width: 400px;
  height: 300px;
  box-shadow: 0px 0px 6px 1px #7c7c7c;
  // background-color: darkkhaki;
}
@media (max-width: 1330px) {
  .blockContainerComputerSecond {
    grid-template-columns: auto auto;
    row-gap: 50px;
  }
}
@media (max-width: 880px) {
  .blockContainerComputerSecond {
    grid-template-columns: auto;
    row-gap: 50px;
  }

  .blockContainerComputerSecondImage {
    width: 300px;
    height: 100%;
  }
}
