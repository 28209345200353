@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.executiveBlock {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  column-gap: 50px;
  // background-color: rebeccapurple;
}
.aimContainer {
  width: 100%;
  height: 500px;
  background-color: #ebfdff;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aimContainerFirst {
  width: 80%;
  height: 100%;
  // background-color: palegreen;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.aimContainerSecond {
  width: 30%;
  height: 100%;
  // background-color: olive;
}
.visionsPara {
  line-height: 35px;
  font-size: 20px;
}
.visions {
  line-height: 40px;
  list-style: square;
  font-size: 17px;
}
.team {
  width: 100%;
  height: 80px;
  // background-color: wheat;
  font-size: 40px;
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #dff2f3;
}
.teamContainerOne {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
}

.teamContainerOneTitle {
  width: 100%;
  height: 50px;
  // background-color: red;
  padding-left: 20px;
}

.teamContainerOneBody {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  column-gap: 50px;
  margin-top: 30px;
  // background-color: rebeccapurple;
}
.teamContainerTwo {
  width: 100%;
  margin-bottom: 30px;
}

.teamContainerTwoTitle {
  width: 100%;
  height: 50px;
  // background-color: red;
  padding-left: 20px;
}

.teamContainerTwoBody {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  column-gap: 50px;
  margin-top: 50px;
  // background-color: rebeccapurple;
}

@media (max-width: 1100px) {
  .teamContainerOneBody {
    grid-template-columns: auto auto;
  }
  .teamContainerTwoBody {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 710px) {
  .teamContainerOneBody {
    grid-template-columns: auto;
  }
  .teamContainerTwoBody {
    grid-template-columns: auto;
  }
}

@media (max-width: 1067px) {
  .aimContainer {
    height: 600px;
  }
}
@media (max-width: 870px) {
  .aimContainer {
    height: 700px;
  }
}
@media (max-width: 661px) {
  .aimContainer {
    height: 800px;
  }
}
@media (max-width: 600px) {
  .aimContainer {
    height: 900px;
  }
}
@media (max-width: 550px) {
  .aimContainer {
    height: 1000px;
  }
}
@media (max-width: 475px) {
  .aimContainer {
    height: 1100px;
  }
}
@media (max-width: 446px) {
  .aimContainer {
    height: 1200px;
  }
}
@media (max-width: 385px) {
  .aimContainer {
    height: 1300px;
  }
}
@media (max-width: 372px) {
  .aimContainer {
    height: 1520px;
  }
}
