.noticeContainer {
  width: 100%;
  height: 500px;
  // background-color: red;
  display: flex;
  padding-right: 5px;
}
.noticeContainerFirst {
  width: 60%;
  height: 100%;
  // background-color: orange;
}
.noticeContainerFirstOne {
  width: 100%;
  height: 45%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  // background-color: orange;
}
.noticeContainerFirstSecond {
  width: 100%;
  height: 55%;
  // background-color: goldenrod;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.noticeContainerSecond {
  width: 40%;
  height: 100%;
  // background-color: rgb(122, 87, 0);
  border: 1px solid silver;
}

.noticeTitle {
  width: 100%;
  height: 10%;
  // background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #0b292d;
  color: white;
}

.noticeBody {
  width: 100%;
  height: 90%;
  // background-color: rgb(85, 255, 0);
}
.noticeBodyOne {
  width: 100%;
  height: 80%;
  // background-color: orange;
}
.noticeBodyTwo {
  // display: none;
  width: 100%;
  height: 20%;
  background-color: #0a292d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 850px) {
  .noticeContainer {
    flex-direction: column;
    height: 1200px;
    padding-left: 5px;
  }

  .noticeContainerFirst {
    width: 100%;
    height: 50%;
  }

  .noticeContainerFirstOne {
    grid-template-columns: auto;
    height: 70%;
  }

  .noticeContainerFirstSecond {
    height: 30%;
  }

  .noticeContainerSecond {
    width: 100%;
    height: 50%;
  }
}
