/* carousel.css */
.carousel-item {
    animation: zoomInOut 2s ease-in-out;
}

/* carousel.css */

/* Default carousel height for larger screens (desktops) */
.carousel-item {
    height: 850px;
    /* Desktop height */
}

.carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

/* Tablet screens (768px and above, but less than 992px) */
@media (max-width: 991px) {
    .carousel-item {
        height: 400px;
        /* Tablet height */
    }
}

/* Mobile screens (less than 768px) */
@media (max-width: 767px) {
    .carousel-item {
        height: 300px;
        /* Mobile height */
    }
}


@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}