.footerContainer {
  width: 100%;
  height: 400px;
  // background-color: red;
  // background-color: aliceblue;

  background-color: #0a2125;
  color: white;
}
.footerContainerOne {
  width: 100%;
  height: 90%;
  // background-color: green;
  display: flex;
}

.footerFirst {
  width: 40%;
  height: 100%;
  // background-color: purple;
  display: flex;
  flex-direction: column;
  padding: 30px 0px 0px 20px;
}

.footerSecond {
  width: 15%;
  height: 100%;
  // background-color: lightgreen;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.footerThird {
  width: 15%;
  height: 100%;
  // background-color: lightpink;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.footerItems {
  list-style-type: none;
}
.footerItems li {
  //   background-color: red;
  margin-top: 10px;
  margin-right: 5px;
}
.footerItems li a {
  margin-left: 5px;
  transition: 0.3s;
  text-decoration: none;
  color: #959595;
}
.footerItems li:hover a {
  padding-left: 10px;
  transition: 0.3s;
  text-decoration: underline;
  color: white;
}
.footerFourth {
  width: 30%;
  height: 100%;
  // background-color: lightseagreen;
}
.footerFourthFirst {
  width: 100%;
  height: 35%;
  // background-color: lightskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerFourthFirst span {
  margin-right: 15px;
  font-size: 25px;
}
.footerFourthFirst span a {
  text-decoration: none;
  color: #a5a5a5;
}
.footerFourthFirst span a:hover {
  color: white;
}
.footerFourthSecond {
  width: 100%;
  height: 40%;
  // background-color: lightgreen;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

.footerFourthThird {
  width: 100%;
  height: 15%;
  // background-color: rgb(84, 116, 231);
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerFourthFourth {
  width: 100%;
  height: 5%;
  // background-color: rgb(255, 243, 69);
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
.footerFourthFifth {
  width: 100%;
  height: 5%;
  // background-color: rgb(255, 78, 78);
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
.footerContainerTwo {
  width: 100%;
  height: 10%;
  // background-color: orange;
  // background-color: #030f1a;
  background-color: #0b2d31;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circleStyle {
  flex-shrink: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  //   background-color: blue;
  margin: 0px 7px;
}

.something {
  background-color: #2a2f38;
  height: 70px;
  width: 210px;
}
.something-1 {
  background-color: #173f44;
  height: 40%;
  width: 100%;
  color: white;
  font-family: sans-serif;
  text-align: center;
  padding-top: 4px;
}
.something-2 {
  background-color: #031b1e;
  height: 60%;
  width: 100%;
  color: white;
  font-family: sans-serif;
  text-align: center;
  padding-top: 10px;
}

@media (max-width: 1200px) {
  .footerNote {
    display: none;
  }
}

@media (max-width: 820px) {
  .footerContainer {
    height: auto;
  }

  .footerContainerOne {
    flex-direction: column;
    height: auto;
  }

  .footerContainerTwo {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  .footerFirst {
    width: 100%;
  }

  .footerSecond {
    width: 20%;
    height: auto;
  }

  .footerThird {
    width: 20%;
    height: auto;
  }

  .footerFourth {
    width: 100%;
  }

  .footerFourthFirst {
    height: auto;
  }

  .footerFourthSecond {
    margin-bottom: 10px;
    height: auto;
  }
}
